import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Tooltip } from 'antd';
const LABEL_TEXT = 'If you check this box the project flow will require QA to be completed before publishing';
const QARequiredCheckbox = ({
  onChange,
  isChecked
}) => <Checkbox data-testid="qa-required-checkbox" onChange={onChange} checked={isChecked}>
    QA Required&nbsp;
    <Tooltip placement="right" title={LABEL_TEXT}>
      <FontAwesomeIcon className="text-info" icon={faInfoCircle} size="lg" />
    </Tooltip>
  </Checkbox>;
export default QARequiredCheckbox;