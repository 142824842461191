import React from 'react';
import { useStyletron } from 'baseui';
import { AvatarWithFallbackOnError } from '@gtintel/platform-atoms';
import { DEFAULT_AVATAR } from '@gtintel/platform-constants';
const UserIcon = ({
  user,
  showName,
  empty_message = 'No partner assigned'
}) => {
  const [css] = useStyletron();
  const avatar = user?.avatar ? user.avatar : DEFAULT_AVATAR;
  const title = user ? user.full_name ?? user.email : empty_message;
  return <div className={css({
    alignItems: 'center',
    display: 'flex',
    gap: '8px'
  })} title={title} data-testid="user-icon">
      <div className={css({
      maxWidth: '32px',
      borderRadius: '16px'
    })}>
        <AvatarWithFallbackOnError imageSrc={avatar} fallback={DEFAULT_AVATAR} alt={title} />
      </div>
      {showName && <p className={css({
      fontWeight: 'bold',
      margin: '0'
    })}>{title}</p>}
    </div>;
};
export const UserPill = ({
  user,
  title,
  showName,
  suffixElement,
  overlap,
  hasBorder,
  background,
  empty_message
}) => {
  const [css, theme] = useStyletron();
  let border = '0';
  let marginLeft = '0';
  const padding = '8px 12px';
  if (overlap || hasBorder) {
    border = '1px solid #e2e2e2';
  }
  if (overlap) {
    marginLeft = suffixElement ? '-28px' : '-14px';
  }
  return <div className={css({
    alignItems: 'center',
    background: background ?? theme.colors.backgroundPrimary,
    border,
    borderRadius: '28px',
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '8px',
    marginLeft,
    padding,
    width: 'fit-content'
  })} title={title} data-testid="user-pill">
      <UserIcon user={user} showName={showName} empty_message={empty_message} />
      {suffixElement}
    </div>;
};
export default UserIcon;