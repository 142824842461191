import { FileExcelOIcon, FileImageOIcon, FilePdfOIcon, FilePowerpointOIcon, FileWordOIcon, FileIcon as NormalFileIcon } from 'react-line-awesome';
const FileIcon = ({
  extension,
  style
}) => {
  const iconStyle = style ? style : {
    fontSize: '40px',
    marginRight: '8px'
  };
  switch (extension) {
    case 'pdf':
      return <FilePdfOIcon data-testid="file-icon" style={iconStyle} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <FileImageOIcon data-testid="file-icon" style={iconStyle} />;
    case 'xlsx':
    case 'xls':
      return <FileExcelOIcon data-testid="file-icon" style={iconStyle} />;
    case 'doc':
    case 'docx':
      return <FileWordOIcon data-testid="file-icon" style={iconStyle} />;
    case 'ppt':
    case 'pptx':
      return <FilePowerpointOIcon data-testid="file-icon" style={iconStyle} />;
    default:
      return <NormalFileIcon data-testid="file-icon" style={iconStyle} />;
  }
};
export default FileIcon;