import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Tooltip } from 'antd';
const LABEL_TEXT = 'This is set in your Team Settings and cannot be changed here. If this box is checked the network partner will not have access to your identity';
const RequestAnonymityCheckbox = ({
  onChange,
  isChecked,
  disabled
}) => <Checkbox data-testid="request-anonymity-checkbox" onChange={onChange} checked={isChecked} disabled={disabled}>
    Request Anonymity&nbsp;
    <Tooltip placement="right" title={LABEL_TEXT}>
      <FontAwesomeIcon className="text-info" icon={faInfoCircle} size="lg" />
    </Tooltip>
  </Checkbox>;
export default RequestAnonymityCheckbox;