import { useState } from 'react';
import { MapMarkerIcon } from 'react-line-awesome';
import { Card } from 'baseui/card';
import { SimpleEnquiries } from '@gtintel/platform-types';
import * as S from './styles';
const SubjectNotes = ({
  notes
}) => {
  const [showMore, setShowMore] = useState(false);
  return <S.SubjectDescription expanded={showMore}>
      <p>{notes}</p>
      {notes.length > 300 && <S.ShowMore onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Show Less' : 'Show More'}
        </S.ShowMore>}
    </S.SubjectDescription>;
};
const SubjectTagLineItems = ({
  subject
}) => {
  const items = [];
  // For Site Visit, the Subject might not be marked as an entity but will have a site_name
  if (!subject.is_individual && (subject.is_entity || subject.site_name)) {
    items.push(subject.is_rural ? 'Rural location' : 'Urban location');
  }
  items.push(subject.is_individual ? 'Individual' : 'Entity');
  if (subject.category) {
    items.push(subject.category);
  }
  if (subject.number_of_enquiries) {
    items.push(SimpleEnquiries[subject.number_of_enquiries]);
  }
  return <S.SubjectTagLine>
      {items.map((item, key) => <span key={key}>{item}</span>)}
    </S.SubjectTagLine>;
};
const JurisdictionTags = ({
  subject
}) => {
  if (subject.jurisdiction) {
    const jurisdictions = subject.jurisdiction.split(',');
    return <S.Tags>
        <span>Jurisdictions</span>
        <S.TagContainer>
          {jurisdictions.map(jurisdiction => <S.TagItem key={jurisdiction}>{jurisdiction}</S.TagItem>)}
        </S.TagContainer>
      </S.Tags>;
  }
};
const SubjectCard = ({
  subject
}) => {
  const addressText = subject.address ? subject.address.split('\n').join(', ') : null;
  return <Card data-testid="subject-card" overrides={{
    Root: {
      style: {
        borderWidth: '1px',
        marginTop: '32px',
        marginBottom: '32px',
        padding: '14px'
      }
    },
    Body: {
      style: {
        fontSize: '14px'
      }
    }
  }}>
      <S.SubjectTitle>{subject.name}</S.SubjectTitle>
      <SubjectTagLineItems subject={subject} />
      {subject.address && <S.Address>
          <MapMarkerIcon />
          <p>{addressText}</p>
        </S.Address>}
      {!subject.address && (subject.description || subject.additional_info) && <SubjectNotes notes={subject.description ? subject.description : subject.additional_info} />}

      <JurisdictionTags subject={subject} />
    </Card>;
};
export const SubjectList = ({
  subjects
}) => {
  return <div data-testid="subject-list">
      <S.Header>
        Subjects <span>({subjects.length})</span>
      </S.Header>
      <S.Row>
        {subjects.map(subject => <SubjectCard key={subject.id} subject={subject} />)}
      </S.Row>
    </div>;
};
export default SubjectCard;