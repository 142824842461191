import React from 'react';
import classNames from 'classnames';
import { Container } from './styles';
const toLowerKebabCase = text => {
  return text.toLowerCase().replace(' ', '-');
};
const TabTitle = ({
  title,
  Icon,
  beta,
  indent,
  betaText = 'Beta'
}) => {
  return <Container data-testid={`tab-title-${toLowerKebabCase(title)}`}>
      {!!Icon && Icon}
      <div className={classNames({
      title: true,
      indent
    })}>{title}</div>
      {beta && <div className="beta">{betaText}</div>}
    </Container>;
};
export default TabTitle;