import { CloseIcon, DownloadIcon } from 'react-line-awesome';
import FileIcon from './FileIcon';
import styles from './styles.module.scss';
const FilePill = ({
  file,
  linkText,
  closeButtonFunc,
  testId
}) => {
  return <div className={styles.pill} key={file.id} data-testid={testId ?? 'file-pill'}>
      <a className={styles.pillLink} href={file.url} download={file.filename}>
        <FileIcon extension={file.extension} />
        <div className={styles.pillText}>
          <h5>{linkText ?? file.filename}</h5>
          <small>{file.size}</small>
        </div>
        {!closeButtonFunc && <DownloadIcon style={{
        fontSize: '24px'
      }} />}
      </a>
      {closeButtonFunc && <div className={styles.closeButton} onClick={() => closeButtonFunc()} onKeyUp={e => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        closeButtonFunc();
      }
    }} data-testid="close-button" role="button" tabIndex={0}>
          <CloseIcon style={{
        fontSize: '20px'
      }} />
        </div>}
    </div>;
};
export default FilePill;