import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
export default (() => {
  const top = 50;
  const [visible, setVisible] = useState(false);
  const [css] = useStyletron();
  useEffect(() => {
    const onScroll = () => {
      if (document.documentElement.scrollTop >= top && !visible) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [top]);
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  return <Button testId="scroll-to-top-button" overrides={{
    Root: {
      style: {
        bottom: visible ? '20px' : '-100px',
        position: 'fixed',
        right: '20px',
        transition: 'all 0.3s ease',
        zIndex: 100
      }
    }
  }} onClick={handleClick} title="Scroll to the top of the page">
      <FontAwesomeIcon icon={faArrowUp} size="lg" className={css({
      color: 'white !important'
    })} />
    </Button>;
});